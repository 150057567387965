import Link from 'next/link';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { setGlobalLoading } from '../../_redux/actions';

const mapStateToProps = (state) => ({
  globalLoading: state.user.globalLoading,
  serviceSegments: state.user.serviceSegments,
});

const mapDispatchToProps = (dispatch) => ({
  setGlobalLoading: (data) => {
    dispatch(setGlobalLoading(data));
  },
});

function ErrorPage(props) {
  const { setGlobalLoading, globalLoading, serviceSegments} = props;

  useEffect(() => {
    const timer = setTimeout(() => {
      setGlobalLoading(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [serviceSegments]);

  console.log('error page', globalLoading);

  return (
    <div className="mt-70 p-5">
      <section className="">
        <div className="container p-0">
          <div className="row">
            <div className="col-lg-12 p-0 ">
              {!globalLoading && <div className="content">
                <h5 className=" text-center">
                  {/* This page is unknown or does not exist */}
                  {/* <p className="my-3">
                    Sorry about that, but the page you are looking for is not available
                  </p> */}
                  <img
                    src={`/images/404.png`}
                    alt="Error Image"
                    className="img-fluid my-5"
                    width={500}
                  />
                  <div className="">
                    <Link href="/">
                      <a
                        title="All In One"
                        className="text-center m-4 search-btn text-decoration-none px-3 py- fs-18 fw-400"
                      >
                        Back to Homepage
                      </a>
                    </Link>
                  </div>
                </h5>
              </div>}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage);
